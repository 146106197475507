@import '~/styles/variables';

.container {
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
  border-bottom: 1px solid #2e2f30;
  padding: 24px;

  @include tablet {
    padding: 16px;
    gap: 16px;
  }

  @include mobile {
    flex-direction: column;
    gap: 30px;
  }
}

.divider {
  min-width: 1px;
  width: 1px;
  height: 100%;
  background: #2e2f30;
}

.settings {
  width: 240px;
  min-width: 240px;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  gap: 24px;

  @include mobile {
    width: 100%;
    gap: 12px;
  }
}

.content {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;

  @include tablet {
    width: calc(100vw - 250px - 16px * 4 - 1px);
  }

  @include mobile {
    width: 100%;
  }
}

.contentHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 16px;

  & > div {
    flex-direction: row !important;
    align-items: center;
    gap: 12px;
  }
}

.contentTabs {
  width: 100%;
  height: 48px;
  min-height: 48px;
}

.contentTabContent {
  width: 100%;
  flex: 1;
}

.dropdownContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .dropdownTitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 17.5px;
    text-align: left;

    color: #b9b9b9;
  }
}
.dropdownMenu {
  position: relative;

  .dropdownTrigger {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @include transition(all);
    background: #141618;
    border-radius: 100px;
    overflow: hidden;

    cursor: pointer;

    img {
      width: 48px;
      height: 48px;
    }

    span {
      width: 100%;
      padding-left: 10px;

      font-size: 14px;
      font-weight: 600;
      line-height: 17.5px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      color: $darkThemeText;
    }

    .selectArrow {
      min-width: 48px;
      width: 48px;
      height: 48px;

      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 18px;
      }
    }

    &:hover {
      background: #292a2d;
    }
  }

  &.dropdownOpen {
    .dropdownTrigger {
      background: #292a2d;
    }
  }
}
.dropdownBody {
  display: flex;

  position: absolute;
  transform: translateY(-100%);

  width: 300px;
  max-height: 420px;
  padding: 16px;

  flex-direction: column;
  gap: 8px;

  background: #141618;
  border: 1px solid #333333;
  border-radius: 6px;
  z-index: 100;
  overflow-x: hidden;
  overflow-y: auto;
  @include scroll-bar();

  &,
  & * {
    touch-action: auto;
  }

  .switch {
    margin-left: auto;
  }

  .groupDivider {
    height: 28px;
    padding: 5px 10px 5px 0;

    font-size: 14px;
    font-weight: 400;
    line-height: 17.5px;
    color: #e3e3e2;
    white-space: nowrap;

    display: flex;
    flex-direction: row;
    align-items: center;

    div {
      flex: 1;
      height: 1px;
      background: #36373c;
      margin-left: 10px;
    }
  }

  .style {
    position: relative;
    height: 48px;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    border-radius: 6px;
    @include transition(all);

    &:not(.noHover):hover {
      background: #292a2d;
      cursor: pointer;
    }

    img {
      width: 48px;
      height: 48px;
      border-radius: 6px;
    }

    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 17.5px;
      color: $darkThemeText;
    }

    .arrowIcon {
      width: 18px;
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      opacity: 0;
      @include transition(all);
    }

    &.activeStyle {
      .arrowIcon {
        opacity: 1;
      }

      span {
        font-weight: 700;
      }
    }
  }
}

.container.mini {
  width: 100%;
  height: fit-content;
  flex-direction: column;
  border-bottom: none;

  padding: 0 24px;

  .settings {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;

    & > div {
      flex: 1;
    }

    @include mobile {
      flex-direction: column;
    }
  }
}

.limitIcon {
  width: 18px !important;
  height: 18px !important;

  top: 50% !important;
  right: 15px !important;
  transform: translateY(-50%);
}
