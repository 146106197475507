@import '~/styles/variables';

.top {
  height: 100%;

  display: flex;
  flex-direction: column;
}
.layersTabs {
  padding-top: 12px;
  height: calc(76px + 12px);
  min-height: calc(76px + 12px);

  @include mobile {
    padding-top: 0;
    height: 49px;
    min-height: 49px;
  }
}

.instrumentSettings {
  width: 100%;
  height: 100%;
  padding: 24px;

  display: flex;
  flex-direction: row;
  align-items: center;

  background: #1d1e21;

  @include mobile {
    min-height: fit-content;
    height: fit-content;
    flex: 1;
    padding: 24px 16px;
  }

  .instrumentSettingsInner {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 24px;

    @include mobile {
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }
  }

  .mobileVolumeControls {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;

    svg {
      color: $darkThemeText;
    }

    .instrumentSetting {
      min-width: unset;
    }
  }

  .midiSettings {
    width: fit-content;
    height: 50px;
    border-radius: 8px;
    border: 1px solid $dark25;
    background: $gray20;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;

    padding: 0 18px;
    margin-left: auto;

    font-size: 14px;
    font-weight: 600;
    line-height: 17.5px;
    color: $darkThemeText;
    white-space: nowrap;

    cursor: pointer;

    svg {
      min-width: 26px;
      width: 26px;
    }

    @include mobile {
      margin-left: 0;
      width: 100%;
    }
  }
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1turn);
  }
}

.instrumentSetting {
  min-width: 350px;

  display: flex;
  flex-direction: column;
  gap: 12px;

  &.octaveSetting {
    min-width: 140px;

    [data-disabled='true'] {
      div {
        opacity: 0.3;
      }
    }

    & > div,
    & > div > div,
    & > div > div > div {
      width: 100%;
    }

    .octaveArrows {
      transform: none;
      height: auto;
      background: none;
      width: auto;

      div {
        border: none;
      }
    }

    .octaveContent {
      flex-grow: 1;
      background: $gray20;
      height: 100%;
      margin-left: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 2px;
    }
  }

  &.disabled {
    .instrumentSettingSwitcher {
      opacity: 0.3;
    }

    & * {
      cursor: default !important;
    }
  }

  .instrumentSettingTitle {
    font-size: 14px;
    font-weight: 600;
    line-height: 17.5px;
    color: $secondaryGray35;
  }

  .instrumentSettingSwitcher {
    position: relative;
    padding: 4px;
    border-radius: 100px;
    border: 1px solid $gray20;

    display: flex;
    flex-direction: row;
    gap: 8px;

    &::after {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;

      content: '';
      border-radius: 100px;
      display: none;

      @include shimmer(2.2s);
      background-image: linear-gradient(
        to right,
        rgba($secondaryGray3, 0.5) 8%,
        rgba($secondaryGray, 0.5) 45%,
        rgba($secondaryGray3, 0.5) 90%
      );
    }

    &.loading {
      &::after {
        display: block;
      }

      filter: blur(1px);
    }

    .currentsSettingValue {
      width: 100%;
      height: 40px;
      padding: 0 16px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      font-size: 14px;
      font-weight: 600;
      line-height: 17.5px;
      color: $darkThemeText;
      white-space: nowrap;

      border-radius: 100px;
      background: $gray20;

      overflow: hidden;
      text-overflow: ellipsis;

      cursor: pointer;
    }

    .btns {
      display: flex;
      flex-direction: row;

      .btn {
        min-width: 40px;
        width: 40px;
        height: 40px;
        background: $gray20;

        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;

        &:nth-child(1) {
          border-right: 1px solid $gray25;
        }

        svg {
          width: 16px;
          height: 16px;
        }

        &.left {
          border-top-left-radius: 50px;
          border-bottom-left-radius: 50px;

          svg {
            transform: rotate(90deg);
          }
        }

        &.right {
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px;

          svg {
            transform: rotate(-90deg);
          }
        }
      }
    }
  }
}

.instrumentSettingAdaptive {
  @include tablet {
    min-width: unset;
    flex: 1;
  }

  @include mobile {
    min-width: 100%;
    width: 100%;
    gap: 8px;
  }
}

.limitIcon {
  position: relative !important;
  top: unset !important;
  right: unset !important;
}
