@import '~/styles/variables';

.container {
  cursor: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  user-select: none;
  white-space: nowrap;

  .loader {
    padding-left: 3px;
    padding-right: 3px;
  }

  img,
  svg {
    color: $iconButtonDefaultColor;
    height: 26px;
  }

  span {
    color: $iconButtonDefaultColor;
    font-size: 16px;
    font-weight: 600;
    padding-left: 8px;
  }

  &.textOnly {
    span {
      padding-left: 0;
    }
  }

  &.enabled {
    cursor: pointer;
    opacity: 1;

    .icon {
      color: $iconButtonDefaultColor;

      path,
      rect {
        @include transition(all);
      }
    }

    span {
      @include transition(color);
    }

    @include nonMobile {
      &:hover {
        &.isHoverWhite {
          .icon {
            color: $darkThemeText;

            path {
              stroke: $darkThemeText;
            }
          }

          span {
            color: $darkThemeText;
          }
        }

        &.isHoverBg {
          background-color: $secondaryGray;
          border-radius: 4px;
        }
      }
    }
  }

  &.isHoverBg {
    @include transition(all);
    padding: 12px 15px;
    @include mobile {
      padding: 6px 8px;
    }
  }

  @include tablet {
    span {
      font-size: 14px;
    }
  }

  &.blur {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      backdrop-filter: blur(4px);
    }
  }
}
