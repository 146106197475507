@import '~/styles/variables';

.wrapper {
  z-index: 4;
  border-radius: 8px;
  position: absolute;
  top: calc(50% - 46px);
  bottom: auto;
  height: 92px;
  width: 260px;
  left: calc(50% - 130px);


  .border {
    @include pseudo-full();
    left: -1px;
    top: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 8px;
    background: linear-gradient(180deg, #F3C12F 0%, #EE9329 100%);
  }

  &[data-login='true'] {
    box-shadow: 0 0 10px 0 rgba($secondaryBlue, 0.4);
    .border {
      display: none;
    }
  }
}

.content {
  background: $darkThemeLight;
  border-radius: 8px;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: $darkThemeText;
}

.description {
  margin: 0 0 12px;
  z-index: 3;
  text-align: center;
  max-width: 190px;
  font-weight: 400;

  span {
    font-weight: 600;
  }

  svg {
    width: 18px;
    left: 15px;
    top: 15px;
    position: absolute;
    @include mobile {
      left: 18px;
    }
    path {
      fill: $secondaryYellow;
    }
  }
}

.upgradePlatinumButton,
.loginButton {
  @include bgTransition($secondaryBrown, $secondaryBrown2);
  height: 30px;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;

  svg {
    width: 18px;
  }

  span {
    font-size: 14px;
    color: $secondaryYellow;
  }
}

.loginButton {
  @include gradientBgTransition(#3d4ff6, #457eed);
  span {
    color: $darkThemeText;
  }
}

.upgradeBg {
  position: absolute;
  height: 50%;
  top: 5px;
  left: 10px;
  right: 20px;
  pointer-events: none;
  @include mobile {
    transform: scale(1.2);
    left: 42px;
    top: 8px;
  }
}
