@import '~/styles/variables';

.container {
  background-color: $secondaryGray10;
  color: $darkTheme;
  font-size: 12px;
  padding: 8px;
  border-radius: 4px;
  font-weight: 500;
  z-index: 10;
  opacity: 1;
  & > div:last-child {
    transform: scale(1.8) rotate(45deg);
  }

  &.primary {
    border-radius: 4px;
    padding: 25px;
    color: $darkThemeText;
    background: linear-gradient(103.32deg, #c145ed 6.46%, #3d4ff7 98.38%);
    font-weight: 600;
    font-size: 16px;
    &:before {
      position: absolute;
      content: '';
      left: 1px;
      top: 1px;
      z-index: -1;
      bottom: 1px;
      right: 1px;
      border-radius: 4px;
      background-color: $darkThemeLight;
    }
    :global(.react-tooltip-arrow) {
      z-index: -2;
      &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        z-index: -1;
        bottom: 1px;
        right: 1px;
        background-color: $darkThemeLight;
      }
    }
  }
}
