@import '~/styles/variables';

.defaultEditorContainer {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.instruments {
  width: fit-content;
  min-width: fit-content;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;

  .divider {
    width: 100%;
    height: 1px;
    background: #1d1e21;
  }

  .instrumentRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;

    @include mobile {
      gap: 8px;
    }
  }
}

.patterns {
  flex: 1;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 29px;
}
.patternMenu {
  position: absolute;
  transform: translateY(-100%);

  background: $dark10;
  border-radius: 12px;

  z-index: 5;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  gap: 12px;

  border: 1px solid $dark25;
  padding: 24px;

  box-shadow: 0px 8px 30px 0px #0000008a;
}
