.mainContainer {
  position: relative;
  user-select: none;
  // display: flex;
  // justify-content: row;
}

.horizontalScroll {
  overflow-x: auto; // Enable horizontal scrolling
  overflow-y: hidden; // Prevent vertical scrolling on the wrapper
  // border: 1px solid #434851;

  scrollbar-width: none;
  -ms-overflow-style: none;
  scrollbar-gutter: none;
  height: 100%;
  width: 100%;
}

.rulerContainer {
  background-color: #1e1d21;
  position: absolute;
  top: 0;
  overflow: hidden;
  width: 100%;
  padding-left: 100px;
  z-index: 65; // Ensure it stays above the content
}
.rulerInnerContainer {
  position: absolute;
  padding-left: 100px;
  display: flex;
  align-items: flex-end;
}

.rulerNumbers {
  position: absolute;
  color: #7f7f7f;
  left: 0;
  font-size: 13px;
}

.rulerNumber {
  font-size: 14px;
  position: absolute;
}

.rulerLabels {
  position: absolute;
  left: 0;
  bottom: 22px;
}

.rulerLabel {
  position: absolute;
  font-size: 16px;
  border: 1px solid #434851;
  border-radius: 2px;
  background-color: #1d1e21;
  color: #7f7f7f;
  padding-left: 3px;
}

.container {
  touch-action: none;
  overflow-y: auto; // Enable vertical scrolling only here
  overflow-x: hidden; // Prevent horizontal scrolling
  position: relative;
  width: auto;
  min-width: fit-content;
  height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scrollbar-gutter: none;
}

.divWrapper::-webkit-scrollbar,
.container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

// Content that overflows the container
.content {
  position: relative;
}

// Piano rows container (positioned relative to allow natural flow)
.pianoRollRows {
  display: flex;
  flex-direction: column-reverse;
}

.pianoRollRow {
  width: 100%; // Full width of each key marker
  box-sizing: border-box;

  &.whiteRow {
    background-color: #282c34; // Light gray for white keys
    box-shadow: rgba(1, 1, 1, 0.3) 0px 1px 0px 0px inset;
  }

  &.blackRow {
    background-color: #1a1d23; // Darker color for black keys
  }
}

.pianoRollNotes {
  position: absolute;
  bottom: 0;
  left: 0;
}

.pianoRollNote {
  position: absolute;
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;

  &.selected {
    border: 2px solid #fff;
  }

  .noteName {
    position: absolute;
    top: 0;
    left: 1px;
    font-size: 12px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.5);
  }

  .noteHandle {
    position: relative;
    width: 4px;
    border-radius: 1px;
    // margin-right: 1px;
    cursor: ew-resize;
    // margin: 0 4px;
    background-color: rgba(255, 255, 255, 0.6);
    // background-color: #1d1e21ee;
    z-index: 1;
  }

  .noteVelocity {
    position: absolute;
    height: 6px;
    margin: 1px;
    border-radius: 1px;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
    bottom: 0;
    left: 0;
  }

  .noteStripe {
    position: absolute;
    background: repeating-linear-gradient(-45deg, white, white 2px, transparent 2px, transparent 5px);
    z-index: 0;
    height: 100%;
  }

  &.ghost {
    border: 2px solid rgba(255, 255, 255, 0.19);
    .noteName {
      visibility: hidden;
    }
    .noteHandle {
      visibility: hidden;
    }
    .noteVelocity {
      visibility: hidden;
    }
    .noteStripe {
      visibility: hidden;
    }
  }

  &.ghostDashed {
    border: 2px dashed rgba(255, 255, 255, 0.153);
    border-radius: 3px;
  }

  &.autocomplete {
    opacity: 0.24;
    border: 2px solid #8c8c8c;
    border-radius: 3px;
    background: repeating-linear-gradient(-45deg, #c3c3c3, #c3c3c3 6px, #313337 6px, #313337 12px);

    &.autocompleteHovered {
      opacity: 1;
      cursor: pointer;
    }
    .autocompleteText {
      font-size: 12px;
      font-weight: 700;
      position: absolute;
      top: 0;
      left: 1px;
    }
  }
}

// The SVG grid positioned absolutely on top of the piano keys
.grid {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%; // Full height
  pointer-events: none; // Allows events to pass through if needed
}

.rulerGrid {
  position: absolute;
  left: 0;
  height: 24px;
}

.selectionBox {
  background-color: rgba(169, 170, 173, 0.268);
  position: absolute;
  border: 1px solid white;
  border-radius: 3px;
  z-index: 10;
  visibility: hidden;
}

.scrollBarX {
  position: absolute;
  bottom: 4px;
  background-color: rgba(255, 255, 255, 0.3);
  height: 10px;
  width: 40px;
  border-radius: 5px;
  z-index: 100;
  pointer-events: none; // Make the scroll bars pass the drag events to the piano roll
}

.scrollBarY {
  position: absolute;
  right: 4px;
  background-color: rgba(255, 255, 255, 0.3);
  height: 40px;
  width: 10px;
  border-radius: 5px;
  z-index: 100;
  pointer-events: none; // Make the scroll bars pass the drag events to the piano roll
}

.leftSideKeysContainer {
  touch-action: none;
  position: absolute;
  left: 0;
  z-index: 60;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scrollbar-gutter: none;
}

.leftSideKeysContent {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  flex-shrink: 0;
}

.leftSideKey {
  width: 100%;
  margin: 0 4px 0 0;
  border-radius: 2px;
  // background-clip: content-box; // Restrict background to content area
  border-bottom: 1px solid #1e1d21;
  border-right: 1px solid #1e1d21;
  // position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .noteName {
    padding-left: 8px;
    font-size: 13px;
    font-weight: 500;
  }

  &.whiteKey {
    background-color: #dcdcdc; // Light gray for white keys
    .noteName {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &.blackKey {
    background-color: #252b32; // Darker color for black keys
    .noteName {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  &.held {
    // background-color: #1b5ee5;
    .noteName {
      color: rgba(255, 255, 255, 0.772);
    }
  }

  &.whiteSampler {
    background-color: #282c34;
  }
  &.blackSampler {
    background-color: #1a1d23;
  }

  &.samplerButton {
    border-bottom: 1px solid #434851;
    border-right: 1px solid #434851;
    border-radius: 0px;
    svg {
      // color: #1bd190;
      width: 100%;
      height: 100%;
    }
  }
}

.zoomControls {
  z-index: 80;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: row;
  border-radius: 2px;
  overflow: hidden;
}

.zoomButton {
  width: 40px;
  height: 40px;
  background-color: #1a1d23;
  border: 1px solid #434851;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
    background-color: #282c34;
  }
}

.autocompleteTabTip {
  position: absolute;
  border: 1px solid #8c8c8c;
  background: repeating-linear-gradient(-45deg, #484a50, #484a50 6px, #26282e 6px, #26282e 12px);
  right: 0;
  border-radius: 3px;
  color: white;
  font-size: 14px;
  padding: 4px;
  z-index: 110;
}

.playhead {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 50;
}

.dragAligner {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  border-left: 1px dashed rgba(255, 255, 255, 0.5);
  visibility: hidden;
}

.topBorder {
  z-index: 100;
  position: absolute;
  left: 0;
  height: 1px;
  background-color: #434851;
  width: 100%;
}

.bottomBorder {
  z-index: 100;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: #434851;
}

.inactiveZone {
  position: absolute;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.37);
  top: 0;
  z-index: 55;
}

.contextMenu {
  position: absolute;
  width: 200px;
  background: #1a1d23;
  border: 1px solid #434851;
  border-radius: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  z-index: 120;
}

.contextMenuItem {
  cursor: pointer;
  color: white;
  font-size: 14px;
  padding: 8px 8px 8px 12px;
  cursor: pointer;
  &:hover {
    background-color: #282c34;
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
