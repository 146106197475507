@import '~/styles/variables';

.cursoring {
  cursor: pointer;
}

.container {
  &.cursoring {
    cursor: not-allowed;
  }
  //padding: 12px;
  width: min-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  user-select: none;
  white-space: nowrap;

  align: center;

  .icon {
    color: $iconButtonDisabledColor;
  }

  &.enabled {
    &.cursoring {
      cursor: pointer;
    }

    .icon {
      color: $iconButtonDefaultColor;
      @include transition(color);
      path {
        @include transition(all);
      }
    }

    &:hover {
      .icon {
        color: $iconButtonHoverColor;
      }

      &.isHoverWhite {
        .icon {
          color: $darkThemeText;
          path {
            stroke: $darkThemeText;
          }
        }
      }

      &.isHoverBlack {
        .icon {
          color: $darkTheme;
          path {
            stroke: $darkTheme;
          }
        }
      }
    }
  }

  &.small {
    .icon {
      width: 16px;
      height: 16px;
    }
  }

  &.medium {
    .icon {
      width: 20px;
      height: 20px;
    }
  }

  &.large {
    .icon {
      width: 24px;
      height: 24px;
    }
  }
}
