@import '~/styles/variables';

.noise {
  position: relative;

  &::before {
    content: '';
    background-image: url('~assets/icons/canvas/noise.png');
    background-size: 100px;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
}
