@import '~/styles/variables';

.switchMarker {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: $darkThemeDarkText;
  margin-left: 0;
  @include transition(all);
}

.switchContainer {
  width: 42px;
  height: 22px;
  border-radius: 22px;
  cursor: pointer;

  & > div {
    z-index: 1;
    position: relative;
    @include transition(all);

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px;
    justify-content: flex-start;
    background: $secondaryGray21;
    border-radius: 22px;
    width: 100%;
    height: 100%;
  }

  &.true {
    & > div {
      background: $secondaryBlue8;
    }

    .switchMarker {
      background: $lightTheme;

      margin-left: 20px;
    }
  }
}
