@import '~/styles/variables';

.mobileModal {
  width: 100%;
  position: fixed !important;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  height: 0;
  &[data-full='true'] {
    border-radius: 0;
  }

  background-color: $darkThemeLight;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: top;

  &.drums {
    background-image: url('~assets/icons/drum-portal-illustration-mobile.svg');

    .mobileControl > div {
      background: transparent !important;
    }
  }

  &.chords {
    background-image: url('~assets/icons/chords-portal-illustration-mobile.svg');

    .mobileControl > div {
      background: transparent !important;
    }
  }

  & * {
    touch-action: auto;
  }
}

.shadow {
  content: '';
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(#282c32, 0.8);
  z-index: 9;
  opacity: 1;
  animation: shadow-anim 0.3s ease-in-out;
}

@keyframes shadow-anim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mobileSwiper {
  position: absolute;
  width: 100%;
  height: 200px;
  margin-bottom: -30px;
  z-index: 100;
}

.content {
  position: relative;
  width: 100%;
  height: calc(100% - 32px);
  color: $darkThemeText;
  display: flex;
  flex-direction: column;
  overflow: auto;
  & > * {
    flex-shrink: 0;
  }

  .modalHead {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 7px;

    margin: 14px 0 16px;

    .modalTitleIcon {
      width: 32px;
      height: 32px;

      svg {
        width: 32px;
        height: 32px;
      }
    }

    .modalTitle {
      font-size: 18px;
      font-weight: 600;
      line-height: 23px;
      color: $darkThemeText;
    }
  }
}

.contentSwipable {
  &,
  & * {
    touch-action: pan-y;
  }
}

.mobileControl {
  @include mobileSwipeControl();
  position: relative;
  top: 0;
  touch-action: pan-y;
  & > div {
    background: $darkThemeLight;
    top: 0;
    border: none;
  }
  &.whiteMode {
    & > div,
    & + .content {
      background: $lightTheme;
    }
  }
}

.mobileModal.canvasMode {
  background: linear-gradient(148.45deg, #161933 0, #0c0e1f 88.73%);
  .mobileControl {
    & > div {
      background: transparent;
    }
  }
}
