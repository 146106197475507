@import '~/styles/variables';

.wrapper {
  position: relative;
  @include nonMobile {
    width: 170px;
  }
  max-width: 100%;
  height: 46px;
}

.selectContainer {
  position: relative;
  min-height: 100%;
  @include nonMobile {
    width: 170px;
  }
  max-width: 100%;

  .selectPickerIcon {
    width: 18px;
    margin-right: 8px;
    color: $darkThemeDark2Text;
  }

  .selectPickerContent {
    overflow: hidden;
    text-overflow: ellipsis;

    &[data-text-left] {
      * {
        text-align: left;
        display: block;
      }
    }

    span {
      color: $editableTextDefaultColor;
      white-space: pre;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
    }
  }

  &.custom {
    * {
      user-select: none;
    }

    position: absolute;
    min-height: auto;
    .selectPicker {
      pointer-events: auto;
      label {
        text-align: left;
        line-height: 12px;
      }
      span {
        text-align: left;
        font-size: 14px;
        display: block;
        line-height: 14px;
        overflow: visible;
      }
      svg[data-rotated='true'] {
        transform: rotate(180deg);
      }
    }

    &.active {
      z-index: 10;
      background: $secondaryGray3;
      box-shadow: 0 6px 6px rgba(0, 0, 0, 0.8);
      border-radius: 6px;
      overflow: hidden;

      .selectPicker {
        background: $secondaryGray;
        border-radius: 6px 6px 0 0;
        border-bottom: 1px solid transparent;
      }
    }

    &.top {
      top: auto;
      bottom: 0;

      &.active {
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.8);

        .selectPicker {
          border-radius: 0 0 6px 6px;
        }
      }
    }
  }
}

.selectLabel {
  width: 100%;
  display: block;
  font-weight: 400;
  font-size: 12px;
  color: $darkThemeDark2Text;
  text-align: center;
  cursor: pointer;

  span {
    color: $editableTextDefaultColor;
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    line-height: 21px;
  }
}

.selectPicker {
  width: 100%;
  height: 46px;
  position: relative;
  pointer-events: none;
  padding-left: 15px;
  padding-right: 15px;
  color: $darkThemeText;
  cursor: pointer;
  border-radius: 6px;
  @include transition(background-color);
  background-color: $secondaryGray3;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  @include mobile {
    width: 100%;
  }

  div {
    flex-grow: 1;
  }

  span {
    margin-top: 4px !important;
  }

  svg {
    flex-shrink: 0;
    width: 18px;
  }
}

.select {
  outline: none;
  box-shadow: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  z-index: 0;
  bottom: 0;
  border-radius: 10px;
  margin: 1px;
  left: 0;
  right: 0;

  @include nonTablet {
    &:hover + .selectPicker {
      background-color: $secondaryGray;
    }
  }
}

.menu {
  padding: 0 !important;
  max-height: 216px;
  overflow: auto;
  @include scroll-bar();

  &::-webkit-scrollbar-track {
    background-color: $secondaryGray3;
  }

  svg {
    width: 22px;
  }

  .option {
    height: 36px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: $darkThemeText;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    justify-content: space-between;
    @include bgTransition($secondaryGray3, #2d2e32);
    &[data-selected='true'] {
      background-color: #2d2e32;
    }
    &:not(:last-child) {
      border-bottom: 1px solid $secondaryGray;
    }
    &[data-icon-start='true'] {
      justify-content: flex-start;
      svg {
        margin-right: 8px;
        width: 18px;
      }
    }

    .selectOptionLabel {
      display: flex;
      overflow: hidden;
      line-height: 21px;

      span {
        color: $editableTextDefaultColor;
        white-space: pre;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
      }
    }
  }
}

.wrapper.justValue {
  width: 100%;
  height: 34px;

  .selectContainer {
    width: 100%;

    .selectPicker {
      height: 34px;

      .selectPickerContent {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        span {
          margin: 0 !important;
        }
      }
    }

    .selectLabel {
      display: none;
    }
  }

  @include mobile {
    height: 40px;

    .selectContainer {
      .selectPicker {
        height: 40px;
      }
    }
  }
}

.selectContainer.justValue {
  width: 100%;

  .selectPicker {
    height: 34px;

    .selectPickerContent {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      span {
        margin: 0 !important;
      }
    }
  }

  .selectLabel {
    display: none;
  }

  @include mobile {
    .selectPicker {
      height: 40px;
    }
  }
}

.wrapper.canvasMode {
  height: 56px;

  &.big {
    width: 256px;

    .selectContainer {
      width: 256px;
    }
  }

  &.small {
    width: 164px;

    .selectContainer {
      width: 164px;
    }
  }

  @include mobile {
    height: 45px;

    &.big {
      width: 100%;
      max-width: 256px;

      .selectContainer {
        width: 100%;
        max-width: 256px;
      }
    }

    &.small {
      width: 100%;
      max-width: 164px;

      .selectContainer {
        width: 100%;
        max-width: 164px;
      }
    }
  }
}

.wrapper.canvasMode,
.selectContainer.canvasMode {
  background: linear-gradient(148.45deg, #161933 0, #0c0e1f 88.73%) !important;
  box-shadow: 0px 4px 10px 0px #00000040;
  border: 1px solid #34374f;
  border-radius: 8px;
  overflow: hidden;

  &.active {
    border-radius: 8px;
    box-shadow: 0px 4px 10px 0px #00000040;
  }

  .selectPicker {
    height: 56px;
    position: relative;
    background: transparent !important;
    z-index: 1;
    border: none !important;

    .selectPickerContent {
      justify-content: flex-start;

      font-family: Poppins;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0.1em;
      text-align: left;

      color: #a5aadd99;
      white-space: nowrap;
    }

    svg {
      width: 24px;
      height: 24px;
      path {
        stroke: #a5aadd99;
      }
    }

    .selectLabel {
      font-family: Poppins;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0.1em;
      text-align: left;
      color: #a5aadd99;
    }

    @include mobile {
      background: linear-gradient(148.45deg, #161933 0, #0c0e1f 88.73%) !important;
      height: 45px;
    }
  }

  .menu {
    z-index: 2;
    max-height: unset;

    .option {
      position: relative;
      height: 56px;
      display: flex;
      align-items: center;
      padding: 0 18px;
      width: 100%;
      border-bottom: none;

      font-family: Poppins;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0.1em;
      text-align: left;
      overflow: hidden;

      color: #a5aadd99;

      background: transparent;
      @include transition(all);

      &[data-selected='true'],
      &:hover {
        background: #14151c80;
        &::after {
          opacity: 1;
        }
      }

      &[data-icon-start='true'] {
        justify-content: flex-start;
        svg {
          margin-right: 8px;
          width: 18px;
        }
      }

      .selectOptionLabel {
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;

        font-family: Poppins;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0.1em;
        text-align: left;

        color: #a5aadd99;

        span {
          color: $editableTextDefaultColor;
          white-space: pre;
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;
        }
      }
    }
  }
}

.wrapper.includePoints,
.selectContainer.includePoints {
  .selectPicker {
    .selectPickerContent {
      padding-left: 20px;
    }

    &::after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      left: 18px;
      top: 50%;
      transform: translateY(-50%);
      background: #65a6e2;
      box-shadow: 0px 0px 1.1570433378219604px 0px #3f6aff;
      box-shadow: 0px 0px 2.314086675643921px 0px #3f6aff;
      box-shadow: 0px 0px 8.099303245544434px 0px #3f6aff;
    }
  }

  .menu {
    .option {
      padding: 0 18px 0 38px;

      &::after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        left: 18px;
        top: 50%;
        transform: translateY(-50%);
        background: #65a6e2;
        box-shadow: 0px 0px 1.1570433378219604px 0px #3f6aff;
        box-shadow: 0px 0px 2.314086675643921px 0px #3f6aff;
        box-shadow: 0px 0px 8.099303245544434px 0px #3f6aff;
        opacity: 0.2;
      }
    }
  }
}
