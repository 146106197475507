@import '~/styles/variables';

.sliderWithText {
  width: 100%;
  position: relative;

  &.hidden {
    display: none;
  }
  &.hideControls {
    .muteButton {
      visibility: hidden;
    }
  }

  .muteButton {
    width: 16px;
    height: 16px;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    svg {
      margin-left: 4px;
      height: 8px;

      path {
        fill: $darkThemeText;
      }
    }
  }

  .sliderContainer {
    .thumb {
      outline: none !important;
    }

    &.yellow > .slider > div:nth-child(1) {
      background: #f2a642;
    }

    &.purple > .slider > div:nth-child(1) {
      background: #b966e0;
    }

    &.blue > .slider > div:nth-child(1) {
      background: #457eed;
    }

    &.blue-marketing > .slider > div:nth-child(1) {
      background: #4564e4;
    }

    &.green > .slider > div:nth-child(1) {
      background: $secondaryGreen;
    }

    &.white > .slider > div:nth-child(1) {
      background: $darkThemeText;
    }

    &.pink > .slider > div:nth-child(1) {
      background: #ef5da8;
    }

    &.cyan > .slider > div:nth-child(1) {
      background: #56ccf2;
    }

    &.lagoon > .slider > div:nth-child(1) {
      background: #23abbe;
    }

    & > .slider > div:nth-child(2) {
      display: none;
    }

    &.disabled {
      & > .slider > div:nth-child(1) {
        background: transparent;
      }

      .thumb {
        background: $secondaryGray2;
      }

      .track {
        background-color: $secondaryGray3;
      }
    }

    &.thin {
      height: calc(10px + 5px * 2);
      margin: -5px 0;
      display: flex;
      align-items: center;

      .slider {
        position: relative;
        height: 20px;
        overflow: visible;
        background: transparent;
        display: flex;
        align-items: center;
        border-radius: 0;

        &::before {
          content: '';
          position: absolute;

          left: 0;
          right: 0;
          top: 50%;
          transform: translateY(-50%);

          height: 2px;
          border-radius: 2px;

          background: $secondaryGray2;
        }
      }

      .track {
        height: 2px;
      }

      .thumb {
        display: block;
        position: relative;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $lightTheme;

        &::before {
          content: '';
          display: block;
          width: 40px;
          height: 20px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    &.thinEnlarged {
      height: 32px;
      margin: -8px 0;

      .slider {
        height: 32px;
      }

      .thumb {
        width: 16px;
        height: 16px;
        border-radius: 16px;

        &::before {
          width: 64px;
          height: 32px;
        }
      }
    }

    &.bpm {
      height: 16px;
      display: flex;
      align-items: center;

      .slider {
        position: relative;
        height: 16px;
        overflow: visible;
        background: transparent;
        display: flex;
        align-items: center;
        &::before {
          content: '';
          position: absolute;

          left: 0;
          right: 0;
          top: 50%;
          transform: translateY(-50%);

          height: 8px;
          border-radius: 8px;

          background: $secondaryGray2;
        }
      }

      .track {
        height: 8px;
        margin: auto;
        background: $secondaryBlue5 !important;
      }

      .thumb {
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 16px;
        background: $lightTheme;
      }
    }

    .slider {
      user-select: none;
      width: 100%;
      height: 16px;
      border-radius: 8px;
      background: $darkThemeDarkText;
      cursor: pointer;
      overflow: hidden;
    }

    .thumb {
      display: none;
    }

    .track {
      background: $darkThemeDarkText;
      border-radius: 8px 0 0 8px;
      height: 16px;
    }
  }
}
