@import '~/styles/variables';

.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
  text-align: left;
  vertical-align: middle;
}

.mask {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  @include transition(background-color);
  background-color: transparent;
}

.modal {
  box-sizing: border-box;
  font-size: 14px;
  filter: drop-shadow(0px 3px 2px $centerModalShadowColor);
  line-height: 1.5;
  list-style: none;
  position: relative;
  pointer-events: none;
  max-height: 100%;
  @include transition(all);
  opacity: 0;
  transform: scale(0.5);

  &.tiny {
    width: 400px;
  }

  &.smallest {
    width: 500px;
  }

  &.small {
    width: 700px;
    max-width: 100%;
  }

  &.fullPage {
    width: 100%;
    height: 100% !important;
    max-width: 100% !important;

    .modalBody {
      height: 100%;
    }

    .closeIcon {
      position: absolute !important;
    }
  }

  &.medium {
    width: 950px;
    max-width: 100%;
  }

  &.large {
    width: 1200px;
  }

  &.canvasMode {
    max-width: calc(100vw - 32px);

    @include mobile {
      height: fit-content;
    }
  }

  @include tablet {
    max-width: 100vw;
  }
  @include mobile {
    max-width: 100vw;
    height: 100%;
  }
}

.modalContent {
  color: $darkThemeText;
  background-color: $secondaryGray4;
  background-clip: padding-box;
  border: 0;
  border-radius: 4px;
  min-height: 240px;
  pointer-events: auto;
  position: relative;
  @include mobile {
    height: 100%;
    border-radius: 0;
  }

  &.light {
    color: $darkThemeText;
    background-color: #fff;
  }
}

.modalHead {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.65);
  border-bottom: 1px solid $centerModalHeadBorderColor;
  border-radius: 4px 4px 0 0;
}

.modalBody {
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}

.closeIcon {
  display: block;
  position: absolute;
  left: 12px;
  padding: 0 !important;
  top: 12px;
  z-index: 2;
  visibility: hidden;
  & > svg {
    width: 26px !important;
    height: 26px !important;
  }
  &.withCloseIcon {
    visibility: visible;
  }

  &.dark .container .icon {
    color: black !important;
  }

  &.canvasMode {
    position: sticky !important;
    width: 100%;
    height: 50px;

    left: 0;
    right: 0;
    top: 0;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 12px !important;

    background: #0f1124;
    box-shadow: 0px 4px 4px 0px #00000040;

    svg path {
      stroke: #6d7196;
    }
  }
}

.modalOpen {
  overflow: hidden;
}

.modalDone .modal {
  opacity: 1;
  transform: scale(1);
}
.modalExitActive .modal {
  opacity: 0;
  transform: scale(0.5);
}
.modalExitDone .modal {
  opacity: 0;
}

.modalDone .mask {
  background-color: rgba(#282c32, 0.7);

  &.canvasMode {
    background-color: rgba(40, 44, 50, 0.7);
  }
}
.modalExitActive .mask {
  background-color: transparent;
}
.modalExitDone .mask {
  background-color: transparent;
}

.loginModal {
  .modalContent {
    border-radius: 18px;
    background-color: $darkThemeLight;
  }
}
