@import '~/styles/variables';

.loginForm {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
}
.loginFormButtons {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.loginFormButton {
  width: 344px;
  height: 48px;

  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  border-radius: 100px;
  outline: none;
  border: none;

  font-size: 14px;
  font-weight: 700;
  cursor: pointer;

  &.email {
    color: $darkThemeText;
    @include bgTransition($dark30, $secondaryGray);
  }

  &.google {
    color: $secondaryGray6;
    @include bgTransition($secondaryGray38, $darkThemeText);
  }

  &.apple {
    color: $darkThemeText;
    @include bgTransition(#000000, $secondaryGray4);
  }

  @include mobile {
    width: 100%;
  }
}
.loginFormAgreement {
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: $secondaryGray36;

  a {
    color: $secondaryGray35;
    text-decoration: underline;
  }
}

.defaultLoginContent {
  padding: 56px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;

  @include mobile {
    padding: 24px;
  }
}
.defaultLoginContentTitle {
  font-weight: 600;
  font-size: 36px;
  text-align: center;
  color: $darkThemeText;
}
.defaultLoginContentSubtitle {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;

  span {
    width: fit-content;

    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: $secondaryGray36;
  }

  div {
    flex: 1;
    height: 1px;
    background: $secondaryGray2;
  }
}

.limitedLoginContent {
  padding: 56px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 64px;

  @include mobile {
    padding: 24px;
  }
}
.limitedLoginLeftSide {
  width: 344px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 48px;

  @include mobile {
    width: 100%;
    gap: 80px;
  }
}
.limitedLoginLeftSideHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.limitedLoginContentTitle {
  font-family: Quicksand;
  font-weight: 600;
  font-size: 36px;
  color: $darkThemeText;
}
.limitedLoginContentSubtitle {
  font-family: Quicksand;
  font-weight: 600;
  font-size: 16px;
  color: $secondaryGray36;

  margin: 8px 0 24px;
}
.limitedLoginRightSide {
  width: fit-content;
  height: 100%;

  @include mobile {
    display: none;
  }
}
.limitedLoginRightSideImg {
  width: auto;
  height: 100%;
  max-height: 450px;
}
