@import '~/styles/variables';

.instrumentsMenu {
  width: 100%;
  height: 100%;

  padding: 0 24px;

  display: flex;
  flex-direction: row;
  gap: 8px;

  border-bottom: 1px solid $lightTheme;

  @include mobile {
    padding-top: 0;
  }
}
.instrumentTab {
  max-width: calc((100% - 8px * 3) / 4);
  height: 100%;
  position: relative;
  flex: 1;
  padding: 0 24px;

  border-radius: 12px 12px 0 0;
  background: $gray20;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  border-left: 1px solid transparent;
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;

  @include tablet {
    padding: 0 8px;
    gap: 8px;
  }

  @include mobile {
    height: 48px;
    min-height: 48px;
    padding: 0;

    justify-content: center;
    border-radius: 8px 8px 0 0;
  }

  cursor: pointer;

  &:hover {
    background: $dark25;

    .showOnHower {
      display: flex;
    }
  }

  & > svg {
    min-width: 24px;
    width: 24px;

    @include mobile {
      min-width: 20px;
      width: 20px;
    }
  }

  .content {
    flex: 1;
    flex-shrink: 0;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    gap: 8px;

    .main {
      width: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 16px;

      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: $secondaryGray32;
      }
    }

    .volumeControl {
      width: calc(100% - 1px);
    }
  }

  .roundedCorner {
    width: 20px;
    height: 20px;

    position: absolute;
    bottom: -1px;

    border-bottom: 1px solid $darkTheme;
    z-index: 2;

    &::after {
      content: '';
      position: absolute;
      left: -1px;
      right: -1px;
      top: -1px;
      bottom: -1px;

      border-bottom: 1px solid $lightTheme;
    }

    &.left {
      left: 0;
      transform: translateX(-100%);
      border-right: 1px solid $darkTheme;

      &::after {
        border-radius: 0 0 10px 0;
        border-right: 1px solid $lightTheme;
      }
    }

    &.right {
      right: 0;
      transform: translateX(100%);
      border-left: 1px solid $darkTheme;

      &::after {
        border-radius: 0 0 0 10px;
        border-left: 1px solid $lightTheme;
      }
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 1px;
    background-color: transparent;
  }

  &.active {
    background: $darkTheme;
    cursor: default;

    border-color: $lightTheme;

    &::after {
      background: $darkTheme;
    }
  }

  &.skeleton {
    justify-content: center;
    gap: 16px;

    @include tablet {
      gap: 8px;
    }

    svg {
      width: 24px;

      path {
        stroke: $secondaryGray35;
      }
    }

    .text {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      color: $secondaryGray35;
    }
  }

  &.blue {
    svg {
      color: #457eed;
    }
  }

  &.yellow {
    svg {
      color: #f2a642;
    }
  }

  &.purple {
    svg {
      color: #b966e0;
    }
  }

  &.pink {
    svg {
      color: #ef5da8;
    }
  }

  &.cyan {
    svg {
      color: #56ccf2;
    }
  }

  &.green {
    svg {
      color: $secondaryGreen9;
    }
  }

  &.lagoon {
    svg {
      color: #23abbe;
    }
  }

  &.muted:not(.active) {
    svg {
      color: $grey10 !important;
    }

    .showOnHower {
      display: flex;
    }
  }
}
.instrumentBtns {
  height: 28px;
  min-width: fit-content;
  display: flex;
  flex-direction: row;

  @include mobile {
    height: 32px;
    gap: 12px;
  }

  &.activeLayer {
    div {
      display: flex;
    }
  }

  div {
    width: 28px;
    height: 28px;
    cursor: pointer;
    background: transparent;
    border-radius: 4px;

    display: none;
    justify-content: center;
    align-items: center;

    @include mobile {
      width: 32px;
      height: 32px;
      border-radius: 3px;
      background: $dark20;
    }

    svg {
      width: 28px;
    }

    &:hover {
      background: $gray20;
    }

    &.disabled {
      background: transparent;
      cursor: default;

      svg {
        color: $grey30 !important;
      }
    }

    &.muted {
      svg {
        color: $grey10;
      }
    }
  }
}
