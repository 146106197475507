@import '~/styles/variables';

.pattern {
  position: relative;
  width: 100%;
  height: 48px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  background: #1d1e21;
  border: 1px solid var(--Dark-25, #2b2e33);
  overflow: hidden;
  cursor: pointer;
  @include transition(all);

  @include mobile {
    width: 100%;
  }

  .left {
    width: 30px;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    font-weight: 700;
    line-height: 17.5px;
    color: $darkThemeText;

    @include transition(all);

    svg {
      width: 16px;
    }
  }

  .center {
    flex: 1;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2px;

    span {
      font-size: 14px;
      font-weight: 700;
      line-height: 17.5px;
      color: $darkThemeText;

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .track {
      height: 4px;
      position: relative;
      width: 100%;

      div {
        top: 0;
        position: absolute;
        height: 4px;
        background: #fff;
        border-radius: 100px;
        @include transition(all);
      }

      &.muttedTrack {
        div {
          background: #595d62;
        }
      }

      &.activeTrack {
        div {
          background: #1da574;
        }
      }
    }
  }

  .right {
    width: 42px;
    height: 100%;
    background: transparent;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 14px;
    }
  }

  &:hover,
  &.menuOpen {
    background: #252629;
  }

  &.inMenu {
    padding-right: 10px;
  }

  &.inMenu.active {
    border-color: #1bd190;
  }

  &.active {
    .left {
      background: #1da574;
    }
  }
}

.instrument {
  width: 40px;
  height: 40px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;

    path {
      @include transition(all);
      color: #595d62 !important;
    }
  }

  &.active {
    path {
      color: #1bd190 !important;
    }
  }

  @include mobile {
    width: 30px;
    height: 30px;
  }
}
