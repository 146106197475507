@import '~/styles/variables';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  margin: 0;
  &,
  a {
    color: $darkThemeDark2Text;
  }
  a {
    text-decoration: underline;
  }
}

.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: $darkThemeText;
  margin-bottom: 12px;
  margin-top: 0;
}

.shadow {
  @include pseudo-full();
  background: radial-gradient(50% 50% at 50% 50%, #1d1e21 0%, rgba(29, 30, 33, 0) 100%);
}

.select {
  margin-bottom: 40px;
  margin-top: 4px;
  &,
  & > div {
    width: 260px;
  }
}
