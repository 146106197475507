@import '~/styles/variables';

.container {
  position: absolute;
  transform: translateY(calc(-100% - 4px));

  padding: 24px;

  background: $dark10;
  border-radius: 12px;

  z-index: 3;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border: 1px solid $dark25;

  @include mobile {
    position: relative;

    width: 100%;
    height: 100%;

    border: none;
    border-radius: 0;

    transform: none;
    background: transparent;
  }
}
