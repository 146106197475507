@import '~/styles/variables';

// volume
.volumeSetting {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}
.volumeSettingContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.volumeSettingTitle {
  font-weight: 600;
  font-size: 14px;
  color: $secondaryGray35;
}
.volumeBtn {
  width: 32px;
  height: 32px;
  border-radius: 3px;
  background: $dark20;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 24px;

    path {
      color: $lightTheme;
    }
  }
}
.melodyMuted {
}
// instrument switch
.instrumentSetting {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  @include mobile {
    width: 100%;
  }
}
.randomPicker {
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  overflow: hidden;

  margin: auto 0;

  background: $dark25;
  cursor: pointer;
  @include transition(all);

  &.loading {
    animation: icon-spin 0.5s ease-in-out;
  }

  &:hover {
    background: $dark30;
  }
}
.instrumentSettingContent {
  min-width: 350px;

  display: flex;
  flex-direction: column;
  gap: 12px;

  @include tablet {
    min-width: unset;
    flex: 1;
  }

  @include mobile {
    min-width: 100%;
    width: 100%;
    gap: 8px;
  }

  &.octaveSetting {
    min-width: 140px;

    [data-disabled='true'] {
      div {
        opacity: 0.3;
      }
    }

    & > div,
    & > div > div,
    & > div > div > div {
      width: 100%;
    }

    .octaveArrows {
      transform: none;
      height: auto;
      background: none;
      width: auto;

      div {
        border: none;
      }
    }

    .octaveContent {
      flex-grow: 1;
      background: $gray20;
      height: 100%;
      margin-left: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 2px;
    }
  }

  &.disabled {
    .instrumentSettingSwitcher {
      opacity: 0.3;
    }

    & * {
      cursor: default !important;
    }
  }

  .instrumentSettingTitle {
    font-size: 14px;
    font-weight: 600;
    line-height: 17.5px;
    color: $secondaryGray35;
  }

  .instrumentSettingSwitcher {
    position: relative;
    padding: 4px;
    border-radius: 100px;
    border: 1px solid $gray20;

    display: flex;
    flex-direction: row;
    gap: 8px;

    &::after {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;

      content: '';
      border-radius: 100px;
      display: none;

      @include shimmer(2.2s);
      background-image: linear-gradient(
        to right,
        rgba($secondaryGray3, 0.5) 8%,
        rgba($secondaryGray, 0.5) 45%,
        rgba($secondaryGray3, 0.5) 90%
      );
    }

    &.loading {
      &::after {
        display: block;
      }

      filter: blur(1px);
    }

    .currentsSettingValue {
      width: 100%;
      height: 40px;
      padding: 0 16px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      font-size: 14px;
      font-weight: 600;
      line-height: 17.5px;
      color: $darkThemeText;
      white-space: nowrap;

      border-radius: 100px;
      background: $gray20;

      overflow: hidden;
      text-overflow: ellipsis;

      cursor: pointer;
    }

    .btns {
      display: flex;
      flex-direction: row;

      .btn {
        min-width: 40px;
        width: 40px;
        height: 40px;
        background: $gray20;

        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;

        &:nth-child(1) {
          border-right: 1px solid $gray25;
        }

        svg {
          width: 16px;
          height: 16px;
        }

        &.left {
          border-top-left-radius: 50px;
          border-bottom-left-radius: 50px;

          svg {
            transform: rotate(90deg);
          }
        }

        &.right {
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px;

          svg {
            transform: rotate(-90deg);
          }
        }
      }
    }
  }
}
.instrumentSettingSmall {
  width: 100%;

  flex-direction: row-reverse;
  align-items: flex-end;
  gap: 12px;

  .randomPicker {
    margin-bottom: 4px;
  }
  .instrumentSettingContent {
    min-width: unset;
    flex: 1;
  }
}
// cursor selector
.cursorSetting {
  width: 100%;

  display: flex;
  flex-direction: row;
  gap: 2px;
}
.cursorSelectorOption {
  flex: 1;
  height: 32px;
  background: $dark25;
  @include transition(all);

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  svg {
    width: 24px;
  }

  &:nth-child(1) {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  &:nth-last-child(1) {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }
}
.cursorSelectorSelected {
  background: $secondaryBlue20;

  &.DRUMS {
    background: $secondaryGreen9;
  }
}
.cursorSelectorClear {
  min-width: 32px;
  width: 32px;
  height: 32px;

  background: $dark20;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  svg {
    width: 16px;

    path {
      stroke: $grey10;
    }
  }
}
// velocity
.velocitySetting {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  @include transition(all);
}
.velocityDisabled {
  opacity: 0.5;

  & * {
    cursor: default !important;
  }
}
.velocityContent {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 6px;
}
.velocityTitle {
  font-weight: 600;
  font-size: 14px;
  color: $secondaryGray35;
}
.velocitySlider {
  & > div > div > div {
    &:nth-last-child(1) {
      width: 16px;
      height: 16px;
    }
  }
}
.velocityRandom {
  min-width: 40px;
  width: 40px;
  height: 40px;

  background: $dark25;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  svg {
    width: 30px;
  }
}
// duration
.durationSetting {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.durationTitle {
  font-weight: 600;
  font-size: 14px;
  color: $secondaryGray35;
}
.durationContent {
  border-radius: 3px;
  border: 2px solid $gray20;

  display: flex;
  flex-direction: row;
  gap: 2px;
}
.durationOption {
  position: relative;
  height: 26px;
  background: $dark25;
  @include transition(all);
  cursor: pointer;

  &::after {
    content: '';

    position: absolute;
    top: 2px;
    bottom: 2px;
    right: 2px;

    width: 3px;
    background: $darkTheme;
    border-radius: 1px;

    @include transition(all);
  }

  &:nth-child(1),
  &:nth-child(2) {
    flex: 1;
  }
  &:nth-child(3) {
    flex: 2;
  }
  &:nth-child(4) {
    flex: 4;
  }
  &:nth-child(5) {
    flex: 8;
  }
}
.durationSelected {
  background: $secondaryBlue20;

  &.DRUMS {
    background: $secondaryGreen9;
  }
}
.durationSelectedLast {
  &::after {
    background: $lightTheme;
  }
}
// tempo
.tempoSetting {
  min-width: 190px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  & > div,
  & > div > div,
  & > div > div > div {
    width: 100%;
  }

  [data-disabled='true'] {
    div {
      opacity: 0.3;
    }
  }

  .tempoTitle {
    font-size: 14px;
    font-weight: 600;
    line-height: 17.5px;

    color: #b9b9b9;
  }

  .tempoSwitcher {
    width: 100%;

    position: relative;
    padding: 4px;
    border-radius: 100px;
    border: 1px solid $gray20;

    display: flex;
    flex-direction: row;
    gap: 8px;

    .tempoArrows {
      transform: none;
      height: auto;
      background: none;
      width: auto;

      div {
        border: none;
      }
    }

    .tempoContent {
      width: fit-content;
      height: 100%;
      margin: 0 2px;
      padding: 0 16px;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;

      background: $gray20;
      text-transform: capitalize;
      white-space: nowrap;
    }

    .btns {
      display: flex;
      flex-direction: row;

      .btn {
        min-width: 40px;
        width: 40px;
        height: 40px;
        background: $gray20;

        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;
        @include transition(all);

        svg {
          width: 16px;
          height: 16px;
        }

        &.left {
          border-top-left-radius: 50px;
          border-bottom-left-radius: 50px;

          svg {
            transform: rotate(90deg);
          }
        }

        &.right {
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px;

          svg {
            transform: rotate(-90deg);
          }
        }
      }
    }
  }
}
// stretch
.stretchSetting {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  @include transition(all);
}
.stretchDisabled {
  opacity: 0.5;

  & * {
    cursor: default !important;
  }
}
.stretchTitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 17.5px;

  color: #b9b9b9;
}
.stretchContent {
  border-radius: 3px;
  border: 2px solid $gray20;

  display: flex;
  flex-direction: row;
  gap: 2px;
}
.stretchOption {
  flex: 1;

  height: 26px;
  background: $dark25;

  display: flex;
  justify-content: center;
  align-items: center;

  @include transition(all);
  cursor: pointer;

  font-size: 14px;
  font-weight: 600;
  line-height: 17.5px;
  color: #ffffff;
}
.stretchSetting:not(.stretchDisabled) {
  .stretchOption:hover {
    background: $dark30;
  }
}
