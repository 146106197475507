@import '~/styles/variables';

.container {
  position: absolute;
  transform: translateY(-100%);

  width: 505px;
  height: 550px;

  background: $dark10;
  border-radius: 12px;

  z-index: 5;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border: 1px solid $dark25;

  @include mobile {
    position: relative;

    width: 100%;
    height: 100%;

    border: none;
    border-radius: 0;

    transform: none;
    background: transparent;
  }

  .header {
    width: 100%;
    min-height: 72px;
    height: 72px;

    border-bottom: 1px solid $dark25;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 16px;
    font-weight: 600;
    color: $darkThemeText;

    @include mobile {
      border: none;
    }
  }

  .content {
    flex: 1;
    flex-shrink: 0;

    overflow: hidden;

    display: flex;
    flex-direction: row;
  }

  .leftMenu .leftMenuItem,
  .rightMenu .rightMenuItem {
    &::before {
      content: '';

      position: absolute;
      left: 0;
      top: 0;

      width: 0;
      height: 100%;
      border-radius: 4px;

      @include gradientBgTransition(#5a7fff, #7bb8ff);
      @include transition(all);

      @include mobile {
        display: none;
      }
    }

    &[data-selected='true'] {
      &::before {
        width: 4px;
      }
    }
  }

  .leftMenu {
    min-width: 140px;
    width: 140px;
    height: 100%;

    overflow-x: hidden;
    overflow-y: auto;

    border-right: 1px solid $dark25;

    @include scroll-bar();

    @include mobile {
      width: 100%;
      min-height: 48px;
      height: 48px;
      padding: 0 16px;

      display: flex;
      flex-direction: row;

      overflow-x: auto;
      overflow-y: hidden;

      border-bottom: 1px solid $dark25;
    }

    .leftMenuItem {
      position: relative;
      height: 74px;
      padding: 0 16px;

      display: flex;
      align-items: center;

      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      color: $grey10;

      cursor: pointer;
      @include transition(all);

      &[data-selected='true'] {
        color: $darkThemeText;
      }

      &:hover {
        color: $darkThemeText;
      }

      @include mobile {
        min-width: 115px;
        height: 48px;
        padding: 14px 16px;
        justify-content: center;
      }
    }
  }

  .rightMenu {
    width: 100%;
    height: 100%;

    overflow-x: hidden;
    overflow-y: auto;

    @include scroll-bar();

    display: flex;
    flex-direction: column;
    gap: 8px;

    @include mobile {
      padding: 16px;
    }

    .bestWith {
      padding: 8px 0 8px 16px;

      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .image {
        position: relative;

        height: 24px;
        width: 32px;
      }

      .text {
        font-size: 13px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0em;

        color: $darkThemeDark2Text;
      }
    }

    .rightMenuItem {
      position: relative;

      min-height: 50px;
      height: 50px;

      padding: 0 18px;

      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;

      cursor: pointer;

      @include mobile {
        border-radius: 8px;
      }

      .loadingIcon {
        padding: 5px;
        margin-left: -5px;
        margin-right: 10px;
        & > div {
          width: 18px;
          height: 18px;
        }
      }

      .playIcon {
        margin-right: 10px;
        margin-left: -5px;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 18px;
          height: 18px;
        }

        &.noPreview {
          opacity: 0.3;
        }
      }

      .name {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: $grey10;
        @include transition(all);

        @include mobile {
          color: $darkThemeText;
        }

        span {
          display: block;
          font-size: 13px;
          line-height: 16px;
          color: $darkThemeDark2Text;
        }
      }

      .platinumIcon {
        margin-left: auto;
        width: 18px;
        height: 18px;
      }

      &[data-selected='true'] {
        background-color: $secondaryGray3;

        .name {
          color: $darkThemeText;
        }

        .playIcon path {
          fill: $darkThemeText;
        }
      }

      &:hover {
        .name {
          color: $darkThemeText;
        }
      }
    }
  }

  .footer {
    width: 100%;
    min-height: 32px;
    height: 32px;

    border-top: 1px solid $dark25;
  }

  &.DRUMS {
    .content {
      .leftMenu .leftMenuItem,
      .rightMenu .rightMenuItem {
        &::before {
          @include gradientBgTransition($secondaryGreen9, $secondaryGreen6);
          @include transition(all);
        }
      }
    }
  }
}
