@import '~/styles/variables';

$modalWrapColor: rgba(#000000, 0.8);
$modalBackground: $darkTheme;
$modalBorder: $dark30;
$modalActionBackground: $dark20;
$modalActionBackgroundHover: $dark25;
$modalHeaderBackground: #222326;
$modalHeaderBorder: $dark30;

$modalWrapZIndex: 10;
$modalZIndex: 11;
$modalActionZIndex: 12;

.modalWrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: $modalWrapZIndex;
  overflow: auto;
  outline: 0;

  @include transition(background-color);
  background-color: transparent;
}

.modalActive {
}
.modalDone {
  background-color: $modalWrapColor;

  .modal {
    opacity: 1;
    transform: scale(1);
  }
}
.modalExitActive {
  background-color: transparent;

  .modal {
    opacity: 0;
    transform: scale(0.5);
  }
}
.modalExitDone {
  background-color: transparent;

  .modal {
    opacity: 0;
  }
}

.modal {
  position: relative;

  width: fit-content;
  height: fit-content;

  max-width: 80vw;
  max-height: 80vh;

  @include transition(all);
  box-sizing: border-box;
  transform: scale(0.5);
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  @include scroll-bar();

  border-radius: 16px;
  background: $modalBackground;
  border: 1px solid $modalBorder;

  z-index: $modalZIndex;
}

.modalAction {
  width: 48px;
  height: 48px;

  position: absolute;
  left: 0;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom-right-radius: 8px;
  background: $modalActionBackground;
  cursor: pointer;
  z-index: $modalActionZIndex;

  &,
  & * {
    transition: background 250ms ease-out, color 250ms ease-out;
  }
  svg {
    width: 24px;
    max-width: 100%;
  }
  svg path {
    color: $secondaryGray35;
  }

  &:hover {
    background: $modalActionBackgroundHover;

    svg path {
      color: $lightTheme;
    }
  }
}

.modalHeader {
  height: 50px;
  width: 100%;

  padding: 0 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;

  background: $modalHeaderBackground;
  border-bottom: 1px solid $modalHeaderBorder;

  .modalAction {
    position: relative;

    min-width: 18px;
    width: 18px;
    height: 18px;

    background: transparent !important;
  }
}
.modalTitle {
  font-family: Quicksand;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0%;
  vertical-align: middle;
  color: $darkThemeText;
}

.fitContentOuter {
  height: fit-content !important;
  max-height: calc(100vh - 52px);

  &,
  & * {
    touch-action: pan-y;
  }
}
.fitContentInner {
  height: fit-content !important;
  max-height: calc(100vh - 52px - 34px);
}
