@import '~/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 56px;

  @include mobile {
    padding: 24px;
  }
}

.wave {
  width: 72px;
  margin-bottom: 16px;
}
.subtitle {
  font-family: Quicksand;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;

  color: $secondaryGray36;

  margin-bottom: 8px;
}
.title {
  font-family: Quicksand;
  font-weight: 600;
  font-size: 28px;
  text-align: center;
  color: $darkThemeText;

  margin-bottom: 32px;

  span {
    @include primaryGradient();
  }
}

.inputContainer {
  width: 100%;
  margin-bottom: 16px;
}
.inputLabel {
  font-family: Quicksand;
  font-weight: 600;
  font-size: 14px;
  color: $darkThemeText;

  margin-bottom: 6px;
}
.inputInput {
  width: 100%;
  padding: 14px 12px;

  border-radius: 12px;
  background: $darkThemeLight;
  border: 1px solid #5f6062;

  font-size: 14px;
  font-weight: 600;
  color: $darkThemeText;
}

.checkboxesContainer {
  margin-bottom: 32px;

  display: flex;
  flex-direction: column;
  gap: 12px;

  span {
    font-family: Quicksand;
    font-weight: 600;
    font-size: 12px;
    color: $secondaryGray36;
  }
  a {
    color: $secondaryGray35;
    text-decoration: underline;
  }
}

.continueButton {
  border-radius: 12px;
  height: 45px;
  width: 100%;
  @include gradientBgTransition(#3d4ff6, #c145ed);
  @include mobile {
    margin-top: auto;
  }
  span {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.16px;
  }
  &[data-disabled='true'] {
    opacity: 0.5;
  }
}
