@import '~/styles/variables';

.animateContainer {
  position: relative;
  height: 670px;
}

.animateSlide {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.closeIcon {
  left: 18px;
  top: 18px;

  width: 24px;
  height: 24px;

  path {
    stroke: $darkThemeText;
  }
}

.modal {
  overflow: hidden;
  border-radius: 18px;
}

.tableContainer {
  transition: all 200ms ease-out;

  & > div {
    width: 100%;
  }
  @include mobile {
    width: 100%;
    min-height: 100%;
    &,
    & * {
      touch-action: pan-y;
    }
  }
}

.container {
  display: flex;
  transition: all 200ms ease-out;
  @include mobile {
    width: 100%;
    flex-direction: column-reverse;
    align-items: center;
    min-height: max(calc(100dvh - 52px - 32px), 100%);
    justify-content: flex-end;
  }
}
