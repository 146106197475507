@import '~/styles/variables';

.container {
  display: flex;
  align-items: center;
}

.leftButton,
.rightButton {
  width: 26px;
  height: 26px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  box-shadow: none;
  padding: 0;
  cursor: pointer;
  svg {
    width: 18px;
    height: 18px;
  }
  @include bgTransition($secondaryGray2, $secondaryGray);
  &[data-disabled='false'] {
    svg path {
      stroke: $darkThemeText;
    }
  }
  &[data-disabled='true'] {
    background-color: $secondaryGray3;
    cursor: auto;
  }
}
.leftButton {
  transform: rotate(-90deg);
}
.rightButton {
  transform: rotate(90deg);
}
.value {
  font-weight: 500;
  font-size: 14px;
  color: $darkThemeText;
  width: 32px;
  display: flex;
  user-select: none;
  justify-content: center;
}
