@import '~/styles/variables';

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  .labelContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    svg {
      width: 20px;
      height: 20px;
    }

    a {
      text-decoration: underline;
    }
  }

  span {
    font-size: 14px;
    font-weight: 600;
    color: $darkThemeText;
  }
}

.box {
  width: 26px;
  height: 26px;
  margin-right: 8px;
  border-radius: 6px;
  background-color: $secondaryGray3;
  @include transition(background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 14px;
    path {
      stroke: $secondaryGray3;
      @include transition(all);
    }
  }

  &.checked {
    background-color: $secondaryGreen2;

    svg path {
      stroke: $darkThemeText;
    }
  }

  &.secondary {
    width: 16px;
    border-radius: 2px;
    height: 16px;
    background-color: $secondaryGray;
    &:not(.checked) {
      svg {
        opacity: 0;
      }
    }
    svg {
      width: 9px;
    }
    &.checked {
      background-color: $secondaryBlue6;
    }
  }
}
