@import '~/styles/variables';

.welcomeModalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;

  padding: 56px;

  @include mobile {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    padding: 24px;
  }
}
.welcomeModalHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.welcomeModalTitle {
  font-family: Quicksand;
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  color: $darkThemeText;
}
.welcomeModalSubtitle {
  font-family: Quicksand;
  font-weight: 600;
  font-size: 16px;
  color: $grey10;
}
.welcomeModalButton {
  width: 200px;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  @include gradientBgTransition(#3d4ff6, #457eed);
  cursor: pointer;
  border: none;
  outline: none;

  font-family: Quicksand;
  font-weight: 700;
  font-size: 14px;
  color: $darkThemeText;

  &:disabled {
    opacity: 0.5;
    cursor: default;
    background: #2b2d30;
  }

  @include mobile {
    width: 100%;
  }
}

.welcomeModalGrid {
  width: 100%;
  display: grid;
  gap: 1px;

  background: $dark30;
  border: 1px solid $dark30;
  border-radius: 8px;
  overflow: hidden;

  @include mobile {
    grid-template-columns: 1fr !important;
  }
}
.welcomeModalGridCell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  background: $dark20;
  padding: 24px 32px;
  cursor: pointer;
  @include transition(all);

  &:hover {
    background: #2b2d30;
  }

  @include mobile {
    flex-direction: row;
    justify-content: space-between;
  }
}
.welcomeModalGridCellActive {
}
.welcomeModalGridCellContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;

  @include mobile {
    flex: 1;
  }
}
.welcomeModalGridCellIcon {
  font-family: Quicksand;
  font-weight: 700;
  font-size: 60px;
  line-height: 120%;

  svg {
    width: 75px;
    height: 75px;
    min-height: 75px;
  }

  @include mobile {
    svg {
      width: 64px;
      height: 64px;
      min-height: 64px;
    }
  }
}
.welcomeModalGridCellTitle {
  font-family: Quicksand;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: $darkThemeText;
}
.welcomeModalGridCellText {
  font-family: Quicksand;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: $grey10;
}

.welcomeModalList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px 16px;

  @include smallTablet {
    width: 100%;
    grid-template-columns: 1fr;
    gap: 8px;
  }
}
.welcomeModalListCell {
  height: 48px;
  padding: 0 24px;

  background: $dark20;
  border: 1px solid $dark30;
  border-radius: 8px;
  cursor: pointer;
  @include transition(all);

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  &:hover {
    background: #2b2d30;
  }
}
.welcomeModalListCellActive {
  background: #2b2d30;
  border-color: $grey10;
}
.welcomeModalListCellIcon {
  font-family: Quicksand;
  font-weight: 700;
  font-size: 32px;
}
.welcomeModalListCellTitle {
  font-family: Quicksand;
  font-weight: 600;
  font-size: 16px;
  color: $darkThemeText;
  user-select: none;
}

.detailedFieldContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.detailedFieldContent {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  padding: 32px 16px 24px;
}
.detailedFieldTitle {
  width: 100%;

  font-family: Quicksand;
  font-weight: 600;
  font-size: 14px;
  color: $secondaryGray38;
}
.detailedFieldInput {
  width: 344px;

  background: transparent;
  border: 1px solid $dark30;
  outline: none;
  border-radius: 6px;
  resize: none;

  padding: 14px 12px;

  font-family: Quicksand;
  font-weight: 600;
  font-size: 14px;
  color: $darkThemeText;

  &::placeholder {
    color: $dark30;
  }

  @include mobile {
    width: 100%;
  }
}
.detailedFooter {
  width: 100%;
  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-top: 1px solid $dark30;
}
.confirmButton {
  width: 344px;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Quicksand;
  font-weight: 700;
  font-size: 14px;
  color: $darkThemeText;

  @include gradientBgTransition(#3d4ff6, #457eed);
  border-radius: 8px;
  cursor: pointer;
  border: none;
  outline: none;

  &:disabled {
    background: #2b2d30;
    opacity: 0.5;
    cursor: default;
  }

  @include mobile {
    width: 100%;
  }
}
