@import '~/styles/variables';

.enterEmailContainer,
.codeSentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fieldContainer {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  padding: 32px 16px 24px;
}
.emailFieldTitle {
  width: 100%;

  font-family: Quicksand;
  font-weight: 600;
  font-size: 14px;
  color: $secondaryGray38;
}
.emailFieldInput {
  width: 344px;
  height: 48px;

  background: transparent;
  border: 1px solid $dark30;
  outline: none;
  border-radius: 6px;

  padding: 0 12px;

  font-family: Quicksand;
  font-weight: 600;
  font-size: 14px;
  color: $darkThemeText;

  &::placeholder {
    color: $dark30;
  }

  @include mobile {
    width: 100%;
  }
}
.fieldError {
  width: 100%;
  height: 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  opacity: 1;
  @include transition(all);

  span {
    font-family: Quicksand;
    font-weight: 600;
    font-size: 12px;
    color: $secondaryRed;
  }
}
.fieldErrorHidden {
  opacity: 0;
}
.confirmButton {
  width: 344px;
  height: 48px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;

  span {
    font-family: Quicksand;
    font-weight: 700;
    font-size: 14px;
    color: $darkThemeText;
  }

  @include gradientBgTransition(#3d4ff6, #457eed);
  border-radius: 8px;
  cursor: pointer;
  border: none;
  outline: none;

  &:disabled {
    background: #2b2d30;
    opacity: 0.5;
    cursor: default;
  }

  @include mobile {
    width: 100%;
  }
}

.footer {
  width: 100%;
  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-top: 1px solid $dark30;

  @include mobile {
    margin-top: auto;
  }
}

.codeSentTitle {
  margin: 16px 0;

  font-family: Quicksand;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: $darkThemeText;
}
.codeFieldInput {
  width: 100%;
  height: 48px;

  border-radius: 6px;
  background: transparent;
  border: 1px solid $dark30;
  outline: none;

  font-family: monospace !important;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  letter-spacing: 8px;
  color: $darkThemeText;
}
.resendCodeButton {
  width: 100%;
  height: fit-content;

  background: transparent;
  border: none;
  outline: none;

  cursor: pointer;
  @include transition(all);

  font-family: Quicksand;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: $darkThemeDark2Text;

  span {
    text-decoration: underline;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
  &:not(:disabled):hover {
    color: $darkThemeText;
  }
}
