@import '~/styles/variables';

.container {
  width: 100%;
  margin-bottom: 40px;
  @include mobile {
    margin-bottom: 24px;
  }
  .text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $secondaryGray;
    margin-bottom: 8px;
    width: 100%;
    p {
      margin: 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
    }
    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      &[data-full='true'] {
        color: #ea5067;
      }
      .verySmall {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
  .slider {
    width: 100%;
    background: $secondaryGray30;
    border-radius: 12px;
    height: 8px;
    position: relative;
    overflow: hidden;
    div {
      background: linear-gradient(121.32deg, #5a7fff 32.99%, #7bb8ff 96.81%);
      position: absolute;
      left: 0;
      height: 100%;
      border-radius: 12px;
      transition: width 2s ease-out;
      &[data-full='true'] {
        background: linear-gradient(121.32deg, #e94f66 32.99%, #c94055 96.81%);
      }
    }
  }
  &.generator {
    margin-bottom: 20px;
    .slider {
      background: $secondaryGray;
    }
    .text {
      color: #b3b4b5;
    }
  }
}
